import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import imageprojekt from "../images/baska-interpolacija-v2.jpg"

// import IconCal from "../svg/icon-cal.svg"
// import IconValue from "../svg/icon-value.svg"
// import IconPie from "../svg/icon-pie.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Projekt dogradnje luke Baška" />
    <main id="main">
      <img
        src={imageprojekt}
        className="alignwide"
        alt="Luka Baška vizualizacija"
      />

      <h1>Projekt &bdquo;Dogradnja luke Ba&scaron;ka&ldquo;</h1>

      <p>
        Projekt &bdquo;Dogradnja luke Ba&scaron;ka&ldquo; prijavljen je na Drugi
        poziv za sufinanciranje unaprjeđenja lučke infrastrukture u funkciji
        obalnog linijskog pomorskog prometa.{" "}
      </p>
      <p>
        Slijedom navedenog 9. rujna 2019. godine sklopljen je Ugovor o dodjeli
        bespovratnih sredstava za projekte financirane iz europskih strukturnih
        i investicijskih fondova u financijskom razdoblju 2014.-2020.
        (referentna oznaka Ugovora: KK.07.4.1.04.0010, u daljnjem tekstu:
        Ugovor) između Ministarstva mora, prometa i infrastrukture (Posredničko
        tijelo 1, u daljnjem tekstu: MMPI), Sredi&scaron;nje agencije za
        financiranje i ugovaranje programa i projekata Europske unije
        (Posredničko tijelo 2, u daljnjem tekstu: SAFU) i ŽLUK (Korisnik).&nbsp;
      </p>
      <p>
        Projektom dogradnje luke Ba&scaron;ka postojeći pristan će se dograditi
        i osigurati nužnu za&scaron;titu akvatorija unutar lučkog područja luke
        Ba&scaron;ka, te omogućiti odgovarajući privez i sigurnost na vezu za
        linijske brodove u cjelogodi&scaron;njoj linijskoj plovidbi. Također će
        se podignuti razina usluge za korisnike javnih brodskih linija, u i oko
        luke, prvenstveno u smislu uređenja prilaza za osobe s invaliditetom,
        ali i dostupnosti putničkog terminala vozilima dostave i hitnih službi.{" "}
      </p>
      <p>
        Projekt je usklađen s lokalnim, regionalnim i nacionalnim (prometnim)
        planovima:
      </p>
      <ul>
        <li>
          Strategijom održivog razvitka Republike Hrvatske (NN 30/09) u dijelu
          ostvarivanja revitalizacije otoka boljim prijevoznim povezivanjem
          kopna i otoka,
        </li>
        <li>
          Strategijom prometnog razvoja Republike Hrvatske od 2017. do 2030.
          godine (NN 131/14) doprinosom u ostvarivanju relevantnog cilja 3:
          Unapređenje regionalne povezanosti u putničkom prometu jačanjem
          teritorijalne kohezije, odnosno cilja 3b Unapređenje regionalne
          povezanosti prema otocima/s otoka/među otocima mjerama te mjerama M.1
          Povećanje intermodalnosti i pristupačnosti, M.3 Za&scaron;tita
          okoli&scaron;a, M.5 Sigurnost na moru, M.6 Pobolj&scaron;anje
          dostupnosti otoka, razvoj luka, M.7 Razvoj drugih luka,
        </li>
        <li>
          Nacionalnim programom razvitka otoka čije je osnovno načelo
          izjednačavanje uvjeta i kvalitete života na otocima i na kopnu,
        </li>
        <li>
          Razvojnom strategijom Primorsko-goranske županije od 2016. do 2020.
          gdje se projekt idealno uklapa kao dio cilja unaprjeđenja
          infrastrukture pomorskog prometnog sustava &scaron;to bi trebalo
          djelovati na ujednačavanje prilika za gospodarski rast i razvoj,
          odnosno uravnoteženi razvoj na cijelom teritoriju Županije. U tom
          smislu, doprinos projekta je posebno nagla&scaron;en u omogućavanju
          integracije pomorskog i cestovnog javnog prijevoza u multimodalni
          putnički prijevoz (usklađivanje javnog autobusnog prijevoza s
          pomorskim prijevozom gdje se putnicima omogućava putovanje s Raba u
          Rijeku bez kori&scaron;tenja automobila),
        </li>
        <li>
          Glavnim planom razvoja prometnog sustava funkcionalne regije Sjeverni
          Jadran u mjeri MJ-PO.9. Poduprijeti županijski pomorski prijevoz i
          međužupanijske pomorske linije kao obveznu javnu uslugu,
        </li>
        <li>
          Programom ukupnog razvoja Općine Ba&scaron;ka 2015-2020, prioritet
          3.1. Razvoj infrastrukture, koji obuhvaća i uređenje luke i dr.
        </li>
      </ul>
      <p>
        Županijska skup&scaron;tina Primorsko-goranske županije je na 17.
        sjednici održanoj 13. i 20. prosinca 2019. godine donijela Odluku o
        utvrđivanju županijske linije u javnom prijevozu u linijskom obalnom
        pomorskom prometu na relaciji Ba&scaron;ka-Lopar-Ba&scaron;ka koja će
        započeti prometovanje nakon realizacije projekta &bdquo;Dogradnja luke
        Ba&scaron;ka&ldquo;. Do kraja provedbe projekta potrebno je sklopiti
        ugovor o javnoj usluzi na predmetnoj liniji.
      </p>
      <p>Projektom su obuhvaćene sljedeće aktivnosti:</p>
      <ul>
        <li>
          <strong>Građevinski radovi FAZA 1 i 2 </strong>- U okviru ove
          aktivnosti planira se izgraditi funkcionalna lučka infrastruktura
          spojena na elektroenergetsku mrežu dogradnjom primarnog lukobrana Vela
          riva (faza 1) za 50,55 m mjereno s vanjske strane, te produženjem
          sekundarnog lukobrana Mala riva (faza 2) za 105,00 m mjereno s vanjske
          strane. Provest će se potrebni građevinski radovi koji uključuju
          početne, zemljane i betonske AB radove, pilote, kamenorezačke, hidro,
          elektromontažne i ostale radove te priključenje na elektroenergetsku
          mrežu. U planu je i nabava i ugradnja urbane opreme (klupice i
          ko&scaron;evi za smeće).
        </li>
        <li>
          <strong>Stručni nadzor građenja </strong>&scaron;to obuhvaća:
        </li>
        <ul>
          <li>
            stručni nadzor sukladno Zakonu o građenju (NN br. 153/13, 20/17,
            39/19) i Zakonu o poslovima i djelatnostima prostornog uređenja i
            gradnje (NN br. 78/15, 118/18) koji sadrži obavljanje stručnog
            nadzora nad građevinskim radovima (s uključenim geodetskim i
            podvodnim nadzorom), elektro nadzor i Koordinatora za&scaron;tite na
            radu II
          </li>
          <li>
            Projektantski nadzor sukladno Zakonu o poslovima i djelatnostima
            prostornog uređenja i gradnje (NN br. NN 78/15, 118/18)
          </li>
        </ul>
        <li>
          <strong>Upravljanje projektom</strong> &scaron;to obuhvaća:
        </li>
        <ul>
          <li>
            uslugu administrativno-tehničke podr&scaron;ke u provedbi projekta
            &scaron;to uključuje izradu Zahtjeva za nadoknadom sredstava,
            pripremu dokumentacije za javnu nabavu, izradu izvje&scaron;ća te
            savjetovanje u provedbi projekta sukladno pravilima programa{" "}
          </li>
          <li>
            uslugu vođenja građevinskog dijela projekta sukladno Zakonu o
            gradnji
          </li>
        </ul>
        <li>
          <strong>Promidžba i vidljivost</strong> &scaron;to obuhvaća izradu
          priopćenja za javnost, objave na Internet stranici prijavitelja,
          izradu promotivnog letka, izradu i postavljanje info ploča,
          organizaciju početne i zavr&scaron;ne konferencija
        </li>
      </ul>
      <p>
        Ukupna vrijednost projekta iznosi 41.166.298,55 kuna, a prethodno
        navedenim Ugovorom ŽLUK su dodijeljena bespovratna sredstva u iznosu{" "}
        <strong>39.709.975,94 kuna</strong>, odnosno u cijelosti su pokriveni
        pozivom definirani prihvatljivi tro&scaron;kovi. Sljedeća tablica sadrži
        detaljniju razradu tro&scaron;kova projekta.
      </p>
      <p>
        Tablica 1.{" "}
        <strong>
          <em>
            Tro&scaron;kovi projekta &bdquo;Dogradnja luke Ba&scaron;ka&ldquo;
          </em>
        </strong>
      </p>
      <table cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <td>
              <p>
                <strong>VRSTA TRO&Scaron;KA</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>IZNOS U KN</strong>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>
                Građevinski radovi (uključene sve hidro i elektro instalacije te
                urbana oprema){" "}
              </p>
            </td>
            <td>
              <p>38.093.838,44</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Stručni nadzor građenja</p>
            </td>
            <td>
              <p>762.500,00</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Projektantski nadzor građenja</p>
            </td>
            <td>
              <p>222.500,00</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Tro&scaron;ak administrativnog vođenja projekta</p>
            </td>
            <td>
              <p>247.500,00</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Voditelj građevinskog dijela projekta</p>
            </td>
            <td>
              <p>243.750,00</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Provedbe promotivne kampanje</p>
            </td>
            <td>
              <p>139.887,50</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>UKUPNO PRIHVATLJIVI TRO&Scaron;KOVI</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>39.709.975,94</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>UKUPNO NEPRIHVATLJIVI TRO&Scaron;KOVI</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>1.456.322,61</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>VRIJEDNOST PROJEKTA</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>41.166.298,55</strong>
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <p> </p>
    </main>
  </Layout>
)

export default IndexPage
